<template>
  <div>
    <h2>General Instructions</h2>
    <CCard>
      <CCardBody>
        <div v-html="getInstructions"></div>
      </CCardBody>
      <CCardFooter class="text-center">
        <CRow class="justify-content-center flex-grow-1">
          <CCol sm="3" lg="2" class="d-flex">
            <CButton
              color="success"
              class="flex-grow-1"
              @click="startExam"
              :disabled="clickedStart"
            >
              <CIcon name="cil-check" />
              Start
            </CButton>
          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import { ajaxCallMixin } from "@/mixins/HttpCommon";
import { localDb } from "@/mixins/localDb";

export default {
  name: "ExamDetails",
  mixins: [ajaxCallMixin, localDb],
  data() {
    return {
      settingStatus: false,
      examData: "",
      clickedStart: false,
      flagLabel: {
        0: "No",
        1: "Optional",
        2: "Mandatory",
      },
    };
  },
  beforeMount() {
    this.examData = this.get("examData", "local");
    this.getExamQuestions();
  },
  beforeRouteEnter(to, from, next) {
    const moduleQuestion = JSON.parse(sessionStorage.getItem("moduleQuestion"));
    if (moduleQuestion) next({ path: "/exam/paper" });
    else next();
  },
  computed: {
    getInstructions() {
      let testData = this.examData.testData;
      let instructions = testData.instructions;
      return instructions[0].instruction;
    },
  },
  methods: {
    getExamQuestions() {
      this.clickedStart = true;
      let modules = this.examData.testData.allowedModules.split(",");
      let data = { moduleId: modules };
      let url = "/test/test/getModuleQuestions";
      let params = {};
      params.data = data;
      params.token = this.examData.authToken;
      this.ajaxCall(url, params, this.prepareExam);
    },
    addVideoTag(stream) {
      var video = document.createElement("video");
      video.setAttribute("id", "inputStream");
      video.style.display = "none";
      video.srcObject = stream;

      document.body.appendChild(video);
    },
    proctoring(vidFlag, imgFlag) {
      return new Promise((resolve, reject) => {
        //no proctoring reuiqred
        if (
          this.flagLabel[vidFlag] === "No" &&
          this.flagLabel[imgFlag] === "No"
        )
          resolve();
        //only video, only image or both reuiqred
        else if (
          navigator.mediaDevices &&
          navigator.mediaDevices.getUserMedia
        ) {
          if (this.flagLabel[vidFlag] === "No") {
            // only image proctoring
            navigator.mediaDevices
              .getUserMedia({ video: true })
              .then((stream) => {
                this.addVideoTag(stream);
                resolve();
              })
              .catch(() => {
                if (this.flagLabel[imgFlag] === "Optional") resolve();
                else reject();
              });
          } else {
            // video proctoring required , image may or may not be required
            navigator.mediaDevices
              .getUserMedia({ video: true, audio: true })
              .then((stream) => {
                this.addVideoTag(stream);
                resolve();
              })
              .catch(() => {
                if (this.flagLabel[vidFlag] === "Optional") resolve();
                else reject();
              });
          }
        }
      });
    },
    geoLocation(geoLocFlag) {
      return new Promise((resolve, reject) => {
        if (this.flagLabel[geoLocFlag] === "No") resolve();
        else if (navigator.geolocation) {
          this.getCurrentLocation()
            .then((position) => {
              resolve();
              this.sendUserLocation(position);
            })
            .catch(() => {
              if (this.flagLabel[geoLocFlag] === "Optional") resolve();
              else reject();
            });
        }
      });
    },
    getCurrentLocation(options = {}) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
      });
    },
    sendUserLocation(position) {
      let geoLocation = {};
      geoLocation.longitude = position.coords.longitude;
      geoLocation.latitude = position.coords.latitude;
      let params = {};
      let data = {};
      data.geoLocation = geoLocation;
      params.data = data;
      let url = "test/proctoring/uploadGeoLocation";
      params.token = this.examData.authToken;
      this.ajaxCall(url, params);
    },
    async applyExamSettings() {
      let examSettings = {};
      if (this.examData.eventDetails != undefined) {
        examSettings = this.examData.eventDetails.advanceSetting;
      } else {
        examSettings.vidProctor = examSettings.imgProctor = examSettings.geoLocation = 0;
      }
      try {
        await this.proctoring(examSettings.vidProctor, examSettings.imgProctor);
        await this.geoLocation(examSettings.geoLocation);
        return true;
      } catch (err) {
        return false;
      }
    },
    async prepareExam(apiResponse) {
      let retry = 0;
      if (apiResponse.error !== true) {
        do {
          this.settingStatus = await this.applyExamSettings();
          retry++;
        } while (!this.settingStatus && retry < 3);

        if (this.settingStatus) {
          this.save("moduleQuestion", apiResponse.data, "session");
          this.save("isExamInProgress", true, "session");
          this.$store.commit("setExamStatus", true);
          this.clickedStart = false;
        }
      } else {
        this.clickedStart = false;
      }
    },
    startExam() {
      if (this.settingStatus) {
        this.$router.push("/exam/paper");
      } else {
        this.$router.push("/exam/end");
      }
    },
  },
};
</script>
